header {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  position: relative;

  nav {
    display: flex;
    justify-content: space-between;
    flex-direction: row;


    ul {
      list-style: none;
      padding: 0;
      display: flex;
      margin: 0;

      li{
        border-left: 4px solid #fcc000;

        &:first-child {
          border-left: none;
        }
      }
    }

    .menu {
      color: white;
      text-decoration: none;
      background: transparent;
      font-size: 21px;
      font-weight: 500;
      display: flex;
      padding: 13px 20px;
      flex-direction: column;
      
      
      span {
        font-size: 0.765em;
        font-weight: 500;
        margin-top: 5px;
      }

      input {
        padding: 5px;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid #ccc;
      }

    }

    button.menu {
      border: none;
    }
    
  }

  .input-search {
    max-height: 50px;
    position: absolute;
    bottom: -40px;
    width: 100%;
    right: 0;

    input {
      padding: 10px;
      width: 100%;
      font-size: 16px;
      border-radius: 4px;
      border: 4px solid var(--dark-color);
    }
  }
}
  