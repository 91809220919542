.cart-list {
    background: var(--secondary-color);
    padding: 32px 20px;

    .product {
        padding: 25px 10px;
        border: 2px solid var(--dark-color);

        .table {
            border-bottom: 2px solid  var(--dark-color);

            .table-row {
                display: flex;
                margin-top: 15px;
            }

           .td  {
                font-size: 28px;
                color: var(--black);
                width: 143px;
            }
        }

    }

    .btn-primary {
        width: 100%;
        height: 64px;
        margin-top: 20px;

    }
}