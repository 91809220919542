

:root {
    --primary-color: #000000;
    --secondary-color: #f2f2f2;
    --success-color: #006618;
    --info-color: #0b76a0;
    --info-hover-color: #03506e;
    --warning-color: #ffc107;
    --danger-color: #dc3545;
    --light-color: #ffc000;
    --dark-color: #042433;
    --white: #fff;
    --black: #000;
}
  
body {
    font-size: 16px;
    font-weight: normal;
    color: var(--black);

    // :focus {
    //     outline: 4px solid var(--light-color);
    //     outline-offset: 2px;
    //     border: none;
    // }
}

@media (forced-colors: active) {
    /* Override styles when WHCM is active */
    .button {
        background: var(--white);
        color: var(--black);
    }
}

@media (forced-colors: active) {
    .focusable-element:focus {
      outline: 2px solid var(--light-color);
    }
  }
  
  

input,
button,
.btn {
  &:focus {
    outline: 4px solid var(--light-color);
    outline-offset: 2px; /* Optional: Adds space between the element and the outline */
    box-shadow: 0 0 5px rgba(255, 192, 0, 0.5);
  }
}

// *:focus {
//   outline: 0.2em var(--light-color) solid;
//   outline-offset: 0.1rem;
// }

// *:focus:not(:focus-visible) {
//   outline: 0;
//   outline-offset: 0.25rem;
// }

// *:focus-visible {
//   outline:  0.2em var(--light-color) solid;
//   outline-offset: 0.25rem;
// }

a {
    text-decoration: none;
    color: var(--black);

    &:focus{
        outline: 4px solid var(--light-color);
        outline-offset: 2px; /* Optional: Adds space between the element and the outline */
        box-shadow: 0 0 5px rgba(255, 192, 0, 0.5);
    }
}



.form-control:focus{
    outline: 4px solid var(--light-color);
    outline-offset: 2px; /* Optional: Adds space between the element and the outline */
    box-shadow: 0 0 5px rgba(255, 192, 0, 0.5);
}

.App {
    max-width: 400px;
    margin: auto;
}

  
h1 {
    font-size: 32px;
    font-weight: bold;
    margin: 0;
}

h2 {
    font-size: 26px;
    font-weight: bold;
    margin: 0;
}

h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.btn-primary {
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: var(--primary-color);
    color: var(--light-color);
    border-radius: 5px;
    border: 1px solid var(--dark-color);
  
    &:hover ,
    &:active {
        background-color: var(--dark-color) !important;
        color: var(--light-color) !important;
        outline-offset: 2px;
        border: none;
    }
}

.btn-default {
    font-size: 22px;
    font-weight: bold;
    width: 100%;
    padding: 8px 14px;
    background: var(--white);
    color: var(--black);
    border: 4px solid var(--dark-color);

    &:hover {
        background-color: var(--white)!important;
        color:ar(--black);
        border: 4px solid var(--dark-color);
    }

    &:focus{
        outline: 4px solid var(--light-color);
        outline-offset: 2px; /* Optional: Adds space between the element and the outline */
        box-shadow: 0 0 5px rgba(255, 192, 0, 0.5);
    
    }
}

.category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    width: 100%;

    .btn-category {
        display: inline-flex;
        align-items: center;
        height: 72px;
        font-size: 24px;
        font-weight: bold;
        text-decoration: none;
        padding: 0 20px;
        background-color: var(--primary-color);
        color: var(--light-color);
        border-radius: 5px;
        border: 1px solid var(--dark-color);
    
        &:hover {
            background-color: var(--dark-color);
            color: var(--light-color);
        }
    }
}


.btn-back {
    margin-top: 20px;
    font-size: 32px;
    font-weight: bold;
    width: 100%;
    padding: 12px 20px;
    background: var(--white);
    color: var(--black);
    border: 4px solid var(--dark-color);

    &:hover {
        background-color: var(--white);
        color:ar(--black);
        border: 4px solid var(--dark-color);
    }
}

.btn-info {
    font-size: 24px;
    font-weight: bold;
    padding: 6px 8px;
    background: var(--info-color);
    color: var(--white);
    border: 2px solid var(--dark-color);

    &:hover,
    &:active{
        background: var(--info-hover-color) !important;
        color: var(--white) !important;
        border: 2px solid var(--dark-color) !important;    
        outline-offset: 2px;
        border: none; 
    }
}

.btn-danger {
    font-size: 24px;
    font-weight: bold;
    padding: 6px 8px;
    background: var(--danger-color);
    color: var(--white);
    border: 2px solid var(--dark-color);

    &:hover {
        background: var(--danger-color) !important;
        color: var(--white) !important;
        border: 2px solid var(--dark-color) !important;
    }
}


.btn-success{
    font-size: 24px;
    font-weight: bold;
    padding: 6px 8px;
    background: var(--success-color);
    color: var(--white);
    border: 2px solid var(--dark-color);

    &:hover {
        background: var(--success-color) !important;
        color: var(--white) !important;
        border: 2px solid var(--dark-color) !important;
    }
}

  