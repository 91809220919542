.product-details {
    background-color: var(--secondary-color);
    padding: 20px;

    .details {
        display: flex;
        flex-direction: column;
    }

    .quantity-input {
        border: 4px solid var(--dark-color);
        background: var(--white);
        font-size: 27px;
        font-weight: bold;
        text-align: center;
        padding: 10px;
        margin-left: 10px;

        input {
            width: 41px;
            height: 41px;
            border: none;
            background: transparent;

            &:focus {
                outline: none;
            }
        }
        
        &:focus-within{
            outline: 4px solid var(--light-color);
        }
    }

    .product-info {
        .btn-primary {
            width: 100%;
            height: 55px;
            margin-bottom: 35px;
        }
    }
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    border-bottom: 2px solid #000;
    margin-bottom: 29px;
    font-size: 26px;
    font-weight: bold;

    i{
        font-size: 19px;
        background-color: black;
        color: white;
        border-radius: 50%;
        width: 22px;
        height: 22px;   
        text-align: center;
    }
}


.product-details {
    // Your existing styles...

    button, select, [role="button"] {
        outline: none; // Remove default outline
        transition: border 0.3s, box-shadow 0.3s; // Smooth transition for focus effects
    }

    button:focus, 
    select:focus, 
    [role="button"]:focus {
        outline: 4px solid var(--light-color); // Change border color on focus
        box-shadow: 0 0 5px rgba(255, 192, 0, 0.5); // Add a shadow to indicate focus
    }

    // Additional styling for the heading buttons
    .heading {
        &:focus {
            outline: 4px solid var(--light-color); // Similar focus styling for headings if they are styled as buttons
            box-shadow: 0 0 5px rgba(255, 192, 0, 0.5);
        }
    }
}
