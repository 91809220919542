.product-list {
    background-color: #f2f2f2;
    padding: 32px 20px;

    span {
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 12px;
        margin-top: 0;
    }
}

.product-link {
    display: flex;
    flex-direction: column;

}

.filter-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.filter {
    padding: 8px 14px;
    border: 4px solid var(--dark-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 22px;
    font-weight: 700;

    &:after {
        content: none;
    }

    &:focus{
        outline: 4px solid var(--light-color);
        outline-offset: 2px; /* Optional: Adds space between the element and the outline */
        box-shadow: 0 0 5px rgba(255, 192, 0, 0.5);
    
    }
}

.product {
    background: var(--white);
    margin-top: 30px;

    .details {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

.cart {
    h2 {
        border-bottom: 2px solid #a6a7a8;
    }
}