
.fashion {
    padding: 32px 20px;
    background-color: #f2f2f2;
    color: #000;

    .category {
       margin-bottom: 34px;
       margin-top: 12px;
    }

    p {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 12px;
        margin-top: 0;
    }

    .btn-category {
        justify-content: center;
    }
}