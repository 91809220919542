.home-section {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    padding: 32px 20px;
    background-color: #f2f2f2;
    color: #000;

    p {
        font-size: 1.25em;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 0;
    }

    &-content {
        width: 100%;
        margin-bottom: 34px;

        p{
            margin-bottom: 0;
        }

        span {
            font-size: 24px;
            font-weight: 800;
        }

        .heading {
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid #000;
            margin-bottom: 29px;
            font-size: 26px;
            font-weight: bold;


            i{
                font-size: 19px;
                background-color: black;
                color: white;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                text-align: center;
            }

            
        }
        
        .btn-category img {
            width: 53px;
            height: 53px;
            margin-right: 10px;
        }
    }
    
}

